import { createSlice } from '@reduxjs/toolkit';
import enumsOperations from './enums-operations';
import errorCodes from '../../shared/errorCodes/errorCodes';

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  const errorCode = action.payload;
  state.error = errorCodes[errorCode] || errorCode;
};

const enumsSlice = createSlice({
  name: 'enums',
  initialState: {
    enumsList: [],
    oneEnum: {},
    total: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    partnersClearError: state => {
      state.error = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(enumsOperations.fetchAllEnums.pending, handlePending)
      .addCase(
        enumsOperations.fetchAllEnums.fulfilled,
        (state, action) => {
          state.enumsList = action.payload.data;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(enumsOperations.fetchAllEnums.rejected, handleRejected)

    //   .addCase(
    //     partnersOperations.fetchAllPartnersByQuery.pending,
    //     handlePending
    //   )
    //   .addCase(
    //     partnersOperations.fetchAllPartnersByQuery.fulfilled,
    //     (state, action) => {
    //       state.partnersList = action.payload.partners;
    //       state.total = action.payload.total;
    //       state.isLoading = false;
    //       state.error = null;
    //     }
    //   )
    //   .addCase(
    //     partnersOperations.fetchAllPartnersByQuery.rejected,
    //     handleRejected
    //   )

      .addCase(enumsOperations.fetchOneEnum.pending, handlePending)
      .addCase(
        enumsOperations.fetchOneEnum.fulfilled,
        (state, action) => {
          state.oneEnum = action.payload;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(enumsOperations.fetchOneEnum.rejected, handleRejected)

      .addCase(enumsOperations.addNewEnum.pending, handlePending)
      .addCase(enumsOperations.addNewEnum.fulfilled, (state, action) => {
        state.enumsList.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(enumsOperations.addNewEnum.rejected, handleRejected)

    //   .addCase(partnersOperations.deletePartner.pending, handlePending)
    //   .addCase(partnersOperations.deletePartner.fulfilled, (state, action) => {
    //     const index = state.partnersList.findIndex(
    //       item => item._id === action.meta.arg
    //     );
    //     state.partnersList.splice(index, 1);
    //     state.isLoading = false;
    //     state.error = false;
    //   })
    //   .addCase(partnersOperations.deletePartner.rejected, handleRejected)

    //   .addCase(partnersOperations.connectExpert.pending, handlePending)
    //   .addCase(partnersOperations.connectExpert.fulfilled, (state, action) => {
    //     // state.partnersList;
    //     state.onePartner = action.payload;
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(partnersOperations.connectExpert.rejected, handleRejected)

      .addCase(enumsOperations.updateEnum.pending, handlePending)
      .addCase(enumsOperations.updateEnum.fulfilled, (state, action) => {
        const index = state.enumsList.findIndex(
          item => item._id === action.payload._id
        );
        state.oneEnum = action.payload;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(enumsOperations.updateEnum.rejected, handleRejected)
      
      .addCase(
        enumsOperations.deleteEnum.pending,
        handlePending
      )
      .addCase(
        enumsOperations.deleteEnum.fulfilled,
        (state, action) => {
          const index = state.enumsList.findIndex(
            item => item._id === action.meta.arg
          );

          state.enumsList.splice(index, 1);
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(
        enumsOperations.deleteEnum.rejected,
        handleRejected
      );
  },
});

export const { setError, partnersClearError } = enumsSlice.actions;

export default enumsSlice.reducer;
