import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const fetchAllEnums = createAsyncThunk(
  'allEnums/fetch',
  async ({ type, page, limit }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/enums/${type}`, {
        params: {
          page,
          limit,
        },
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchOneEnum = createAsyncThunk(
  'oneEnum/fetch',
  async ({ id, type }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/enums/${type}/${id}`);
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const addNewEnum = createAsyncThunk(
  'addNewEnum/post',
  async ({ dataBody }, thunkApi) => {
    const { type, name } = dataBody;
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post(`/enums/${type}`, {
        name: name,
      });
      toasty.toastSuccess('Creating successfully');
      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message.toLowerCase());
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const updateEnum = createAsyncThunk(
  'updatePartner/put',
  async ({ dataBody }, thunkApi) => {
    const state = thunkApi.getState();
    const { id, type, name } = dataBody;
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.put(`/enums/${type}/${id}`, {
        name: name,
      });
      toasty.toastSuccess('Update successfully');
      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const deleteEnum = createAsyncThunk(
  'deleteEnum/delete',
  async ({ id, type }, thunkApi) => {
    const state = thunkApi.getState();

    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.delete(`/enums/${type}/${id}`, {});
      console.log(response.data);
      toasty.toastSuccess('Deleting successfully');
      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const enumsOperations = {
  fetchAllEnums,
  fetchOneEnum,
  addNewEnum,
  deleteEnum,
  updateEnum,
};

export default enumsOperations;
