import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const fetchAllRequests = createAsyncThunk(
  'allRequests/fetch',
  async ({ page, limit }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/contact-us', {
        params: {
          page,
          limit,
        },
      });

      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchAllRequestsByQuery = createAsyncThunk(
  'allRequestsByQuery/fetch',
  async ({ page, limit, role }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/contact-us', {
        params: {
          page,
          limit,
          role,
        },
      });

      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchOneRequest = createAsyncThunk(
  'oneConsultation/fetchOne',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.get(`/contact-us/${_id}`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const deleteRequest = createAsyncThunk(
  'deleteRequest/delete',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.delete(`/contact-us/${_id}`, {});
      toasty.toastSuccess('Deleted successfully');
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const readRequest = createAsyncThunk(
  'readRequest/read',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/contact-us/${_id}`, {});
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const requestsOperations = {
  fetchAllRequests,
  fetchAllRequestsByQuery,
  fetchOneRequest,
  deleteRequest,
  readRequest,
};

export default requestsOperations;
