import { Route, Routes, Navigate } from 'react-router-dom';
import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from './shared/components/Loader/Loader';
import { current } from './redux/auth/auth-operations';

import { SocketProvider } from './contexts/socketContext';
import { getAuth } from './redux/auth/auth-selectors';

const Layout = lazy(() => import('./modules/Layout/Layout'));
const PublicRoute = lazy(() => import('./modules/PublicRoute/PublicRoute'));
const PrivateRoute = lazy(() => import('./modules/PrivateRoute/PrivateRoute'));
const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'));
const BOAdminUsersPage = lazy(() =>
  import('./pages/BOAdminUsersPage/BOAdminUsersPage')
);
const CustomersPage = lazy(() => import('./pages/CustomersPage/CustomersPage'));
const ExpertsPage = lazy(() => import('./pages/ExpertsPage/ExpertsPage'));
const ReviewsPage = lazy(() => import('./pages/ReviewsPage/ReviewsPage'));
const SettingPage = lazy(() => import('./pages/SettingPage/SettingPage'));

const CreatePasswordPage = lazy(() =>
  import('./pages/CreatePasswordPage/CreatePasswordPage')
);
const LinkExpiredPage = lazy(() =>
  import('./pages/LinkExpiredPage/LinkExpiredPage')
);

const TherapistInfoPage = lazy(() =>
  import('./pages/TherapistInfoPage/TherapistInfoPage')
);

const TherapistCreatePasswordPage = lazy(() =>
  import('./pages/TherapistCreatePasswordPage/TherapistCreatePasswordPage')
);

const ConsultationsPage = lazy(() =>
  import('./pages/ConsultationsPage/ConsultationsPage')
);

const CustomersInfoPage = lazy(() =>
  import('./pages/CustomerInfoPage/CustomersInfoPage')
);

const ComplaintsPage = lazy(() =>
  import('./pages/ComplaintsPage/ComplaintsPage')
);

const ApplicationLogsPage = lazy(() =>
  import('./pages/ApplicationLogsPage/ApplicationLogsPage')
);

const PartnersPage = lazy(() => import('./pages/PartnersPage/PartnersPage'));

const PartnerDetailsPage = lazy(() =>
  import('./pages/PartnersPage/PartnerDetailsPage')
);

const RequestsPage = lazy(() => import('./pages/RequestsPage/RequestsPage'));
const RequestDetailsPage = lazy(() =>
  import('./pages/RequestDetailsPage/RequestDetailsPage')
);

const SupportChatsPage = lazy(() =>
  import('./pages/SupportChatPage/SupportChatPage')
);
const SupportChatsDetailsPage = lazy(() =>
  import('./pages/SupportChatPage/SupportChatDetailsPage')
);

const ConsultationsDetailsPage = lazy(() =>
  import('./pages/ConsultationsDetailsPage/ConsultationsDetailsPage')
);

function App() {
  const dispatch = useDispatch();

  const { token } = useSelector(getAuth);

  useEffect(() => {
    dispatch(current());
  }, [dispatch, token]);

  return (
    <Suspense fallback={<Loader />}>
      {token ? (
        <SocketProvider token={token}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route element={<PublicRoute />}>
                <Route index path="login" element={<LoginPage />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route index element={<HomePage />} />
                <Route path="admin" element={<BOAdminUsersPage />} />
                <Route path="users">
                  <Route index element={<CustomersPage />} />
                  <Route path=":id" element={<CustomersInfoPage />} />
                </Route>
                <Route path="experts">
                  <Route index element={<ExpertsPage />} />
                  <Route path="id/:id" element={<TherapistInfoPage />} />
                  <Route
                    path="consultation-details/:id"
                    element={<ConsultationsDetailsPage />}
                  />
                </Route>
                <Route path="consultations">
                  <Route index element={<ConsultationsPage />} />
                </Route>
                <Route path="reviews">
                  <Route index element={<ReviewsPage />} />
                </Route>
                <Route path="complaints">
                  <Route index element={<ComplaintsPage />} />
                </Route>
                <Route path="partners">
                  <Route index element={<PartnersPage />} />
                  <Route path="details" element={<PartnerDetailsPage />} />
                </Route>

                <Route path="requests">
                  <Route index element={<RequestsPage />} />
                  <Route path="details" element={<RequestDetailsPage />} />
                </Route>

                <Route path="setting">
                  <Route index element={<SettingPage />} />
                  {/* <Route path="details" element={<RequestDetailsPage />} /> */}
                </Route>

                <Route path="logs">
                  <Route index element={<ApplicationLogsPage />} />
                </Route>
                <Route path="chats">
                  <Route index element={<SupportChatsPage />} />
                  <Route
                    path="expert/:id"
                    element={<SupportChatsDetailsPage />}
                  />
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="login" />} />
          </Routes>
        </SocketProvider>
      ) : (
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Navigate to="login" />} />
              <Route path="login" element={<LoginPage />} />
            </Route>
            <Route path="/set-password" element={<CreatePasswordPage />} />
            <Route
              path="/expert/set-password"
              element={<TherapistCreatePasswordPage />}
            />
          </Route>

          <Route path="/not-found" element={<LinkExpiredPage />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Routes>
      )}
    </Suspense>
  );
}

export default App;
